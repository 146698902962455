<template>
  <div>

    <form id="antares-form">
      <h1 class="center">
        {{ $t('antares.title') }}
      </h1>
      <div class="form-group">
        <h2>{{ $t('antares.identite') }}</h2>
        <InputClassic
          v-model="data.nomEtablissement"
          :label="$t('antares.nom-etablissement')"
          status="required"
        />
        <InputClassic
          v-model="data.fonction"
          :label="$t('antares.fonction')"
          status="required"
        />
        <div class="flex-vcenter">
          <SelectClassic
            v-model="data.genre"
            :label="$t('antares.genre')"
            :options="genres"
            status="required"
          />
          <InputClassic
            v-model="data.prenom"
            :label="$t('antares.prenom')"
            status="required"
          />
          <InputClassic
            v-model="data.nom"
            :label="$t('antares.nom')"
            status="required"
          />
        </div>
        <div class="flex-vcenter">
          <InputClassic
            v-model="data.email"
            :label="$t('antares.email')"
            status="required"
          />
          <InputClassic
            v-model="data.emailConfirmation"
            :label="$t('antares.email') + ' (' + ($t('antares.confirmation')) + ')'"
            status="required"
          />
        </div>
        <div class="flex-vcenter">
          <InputClassic
            v-model="data.telephone"
            :label="$t('antares.telephone')"
            status="required"
            type="phone"
          />
        </div>
      </div>
      <div class="form-group">
        <h2>{{ $t('antares.facturation') }}</h2>
        <InputClassic
          v-model="data.facturation.adresse"
          :label="$t('antares.adresse')"
          status="required"
        />
        <InputClassic
          v-model="data.facturation.complement"
          :label="$t('antares.complement')"
        />
        <div class="flex-vcenter">
          <InputClassic
            v-model="data.facturation.codepostal"
            :label="$t('antares.codepostal')"
            status="required"
          />
          <InputClassic
            v-model="data.facturation.ville"
            :label="$t('antares.ville')"
            status="required"
          />
          <SelectClassic
            v-model="data.facturation.pays"
            :label="$t('antares.pays')"
            status="required"
            :options="pays"
          />
        </div>
      </div>
      <div class="form-group">
        <h2>{{ $t('antares.livraison') }}</h2>
        <ButtonCheckbox
          v-model="data.livraison.idem"
          :label="$t('antares.idem')"
          name="idem"
          value="true"
        />
        <InputClassic
          v-if="!data.livraison.idem"
          v-model="data.livraison.adresse"
          :label="$t('antares.adresse')"
          status="required"
        />
        <InputClassic
          v-if="!data.livraison.idem"
          v-model="data.livraison.complement"
          :label="$t('antares.complement')"
        />
        <div class="flex-vcenter">
          <InputClassic
            v-if="!data.livraison.idem"
            v-model="data.livraison.codepostal"
            :label="$t('antares.codepostal')"
            status="required"
          />
          <InputClassic
            v-if="!data.livraison.idem"
            v-model="data.livraison.ville"
            :label="$t('antares.ville')"
            status="required"
          />
          <SelectClassic
            v-if="!data.livraison.idem"
            v-model="data.livraison.pays"
            :label="$t('antares.pays')"
            status="required"
            :options="pays"
          />
        </div>
      </div>
  
      <ButtonClassic
        :disabled="!isFormValid"
        :label="$t('antares.envoyer')"
        variant="solid"
        color="primary"
        @click="sendAntaresForm"
      />
    </form>
    <ModalAntaresConfirmation/>
  </div>
</template>

<script>
import {
  ButtonClassic,
  ButtonCheckbox,
  InputClassic,
  SelectClassic,
  copyToClipboard,
} from "@lde/core_lde_vue";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

import config from "@/config";

import ModalAntaresConfirmation from "@/components/modals/ModalAntaresConfirmation.vue";

/**
 * Formulaire pour Antares
 */
export default {
  name: "AntaresForm",
  components: {
    ButtonClassic,
    ButtonCheckbox,
    InputClassic,
    ModalAntaresConfirmation,
    SelectClassic,
  },
  data() {
    return {
      hotlineLink: process.env.VUE_APP_HOTLINE_URL,
      data: {
        nomEtablissement: "", // Nom de l'établissement
        fonction: "", // Fonction
        genre: {}, // Genre
        prenom: "", // Prénom
        nom: "", // Nom
        email: "", // Adresse e-mail
        emailConfirmation: "", // Adresse e-mail (répétition)
        telephone: "", // Téléphone
        facturation: {
          pays: {
            value: "Deutschland",
            label: this.$t("antares.pays-liste.allemagne"),
          }, // Pays
          ville: "", // Ville
          codepostal: "", // Code postal
          complement: "", // Complément d'adresse
          adresse: "", // Rue
        },
        livraison: {
          idem: true,
          pays: {
            value: "Deutschland",
            label: this.$t("antares.pays-liste.allemagne"),
          }, // Pays
          ville: "", // Ville
          codepostal: "", // Code postal
          complement: "", // Complément d'adresse
          adresse: "", // Rue
        },
      },
      genres: [
        { value: "Herr", label: this.$t("antares.genres.herr") },
        { value: "Frau", label: this.$t("antares.genres.frau") },
        { value: "Divers", label: this.$t("antares.genres.divers") },
      ],
      pays: [
        { value: "Deutschland", label: this.$t("antares.pays-liste.allemagne") },
        { value: "Frankreich", label: this.$t("antares.pays-liste.france") },
      ],
    };
  },
  computed: {
    isFormValid() {
      const fields = (
        this.data.nomEtablissement
        && this.data.fonction
        && this.data.genre?.value
        && this.data.prenom
        && this.data.nom
        && this.data.email
        && this.data.emailConfirmation
        && this.data.telephone
        && (
          this.data.facturation.adresse
          && this.data.facturation.codepostal
          && this.data.facturation.ville
          && this.data.facturation.pays?.value
        )
        && (
          this.data.livraison.idem
          || (
            this.data.livraison.adresse
            && this.data.livraison.codepostal
            && this.data.livraison.ville
            && this.data.livraison.pays?.value
          )
        )
      );
      const confirmation = this.data.email === this.data.emailConfirmation;
      const isEmailValid = this.data.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

      return !(!fields || !confirmation || !isEmailValid);
    },
    ...mapGetters(["user", "country"]),
    isDe() {
      return this.country === "de";
    },
    mailLdeDe() {
      return config.mailsDe.lde;
    },
    mailLdeFr() {
      return config.mailsFr.lde;
    },
  },
  mounted() {
    if (this.user) {
      this.data.email = this.user?.email;
      this.data.emailConfirmation = this.user?.email;
      this.data.prenom = this.user?.contact.prenom;
      this.data.nom = this.user?.contact.nom;
    }
  },
  methods: {
    copyToClipboard,
    sendAntaresForm() {
      const ra = {};
      const la = {};
      Object.keys(this.data.facturation).forEach((key) => {
        const fullKey = `facturation_${key}`;
        ra[fullKey] = this.data.facturation[key]?.value ?? this.data.facturation[key];
      });
      if (!this.data.livraison.idem) {
        Object.keys(this.data.livraison).forEach((key) => {
          const fullKey = `livraison_${key}`;
          la[fullKey] = this.data.livraison[key]?.value ?? this.data.livraison[key];
        });
      }

      Api({ csrf_exempt: true }).post("/antares_form_send/", {
        genre: this.data.genre.value,
        nom_etablissement: this.data.nomEtablissement,
        fonction: this.data.fonction,
        prenom: this.data.prenom,
        nom: this.data.nom,
        email: this.data.email,
        telephone: this.data.telephone,
        ...ra,
        livraison_idem: this.data.livraison.idem,
        ...(this.data.livraison.idem
          ? {}
          : la
        ),
      })
        .then((response) => {
          const data = response;
          if (response.status === 200) {
            this.$toast.success({
              title: this.$t("general.succes"),
              content: this.$t("antares.success"),
            });
            this.$modal.show("modal_antares_confirmation");
          } else if (response.status === 429) {
            this.$toast.error({
              title: this.$t("general.erreur"),
              content: this.$t("antares.error-too-many-requests"),
            });
          } else if (data.error) {
            this.$toast.error({
              title: this.$t("general.erreur"),
              content: this.$t("antares.error", { msg: data.error }),
            });
          } else {
            this.$toast.error({
              title: this.$t("general.erreur"),
              content: this.$t("antares.unknown-error"),
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$toast.error({
            title: this.$t("general.erreur"),
            content: this.$t("antares.error", { msg: error }),
          });
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_antares_form.scss";
</style>
