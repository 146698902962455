<template>
  <Modal
    id="modal_antares_confirmation"
    element-to-focus="button"
    @hidden="!$event && hide()"
  >
    <template #title>
      {{ $t("antares.confirmation-title") }}
    </template>
    <template #content>
      <p
        class="text-regular"
        v-html="$t('antares.confirmation-content').replaceAll('\n', '<br/>')"
      />
    </template>
    <template #footer>
      <span class="help">
        <i>{{ $t("antares.redirection-site-gmbh") }}</i>
      </span>
      <ButtonClassic
        label="OK"
        variant="solid"
        color="primary"
        @click="confirm"
      />
    </template>
  </Modal>
</template>

<script>
import {
  ButtonClassic,
  Modal,
} from "@lde/core_lde_vue";

export default {
  components: {
    ButtonClassic,
    Modal,
  },
  emits: ["hidden"],
  methods: {
    confirm() {
      window.location.href = "https://lde.de";
    },
    hide() {
      this.$emit("hidden", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss"as var;

.modal-footer .help {
  font-size: var.$font-small;
  margin-right: var.$space-x-tiny;
}
</style>